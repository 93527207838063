import React from 'react';
import './StatusBar.css';

const StatusBar = ({ message, type }) => {
  return (
    <div className={`status-bar ${type}`}>
      {message}
    </div>
  );
};

export default StatusBar;
