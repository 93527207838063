import React, { useState, useEffect } from 'react';
import Title from './components/Title';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './components/Chart';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid'
import PickSummary from './components/PickSummary';
import { useMediaQuery,useTheme } from '@mui/material';


export default function Summary({userid}) {
  const [totalScore, setTotalScore] = useState(0);
  const [periods, setPeriods] = useState([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm')); // Adjust breakpoint as needed

  useEffect(() => {
    fetch(`/api/summary/${userid}`)
      .then((res) => res.json())
      .then((data) => {
        setTotalScore(data.score);
        setPeriods(data.periods);
      });
  }, [userid]);  

  const summary_columns = [
    { field: 'id', headerName: 'ID'  },
    { field: 'end', headerName: 'Period', flex: .5, minWidth: 90 },
    { field: 'events', headerName: 'Events', flex: 3, minWidth: 100 },
    { field: 'num_picks', headerName: 'Picks', flex: 0.5, minWidth: 80 },
    { field: 'score', headerName: 'Score', flex: 0.5, minWidth: 90 },
    { field: "selected", headerName: '', flex: 0.5, minWidth: 100 , 
      renderCell: (cellValues) => {
        return (PickSummary(userid, cellValues.row.id))
      }
    },    
  ]

  function SummaryGrid() {
    return <DataGrid
      disableColumnMenu
      autoHeight
      rows={periods}
      columns={summary_columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        columns: {
          columnVisibilityModel: {
            id: false,
            events: !isMobile,
          },
        },
      }}
      pageSizeOptions={[10, 15, 25, 50]}
      density={'compact'}
      disableRowSelectionOnClick
      disableColumnSelector
      ignoreDiacritics />;
  }

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100vh' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}
              >
                <Chart  periods={periods} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}
              >
                <Title>Season Score</Title>
                <Typography component="p" variant="h4">
                {totalScore}
                </Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                {new Date().toDateString()}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                {SummaryGrid()}
              </Paper>
            </Grid>
          </Grid>

          </div>
    </React.Fragment>
  );


}
