import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function AllAthletesRadioGroup({allAthletes, setAllAthletes}) {

  const handleChange = (event) => {
    setAllAthletes(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="all-athletes-radio-buttons-group">Athlete Selection</FormLabel>
      <RadioGroup
        // aria-labelledby="demo-controlled-radio-buttons-group"
        row
        name="all-athletes-radio-buttons-group"
        value={allAthletes}
        onChange={handleChange}
      >
        <FormControlLabel value={true} control={<Radio />} label="All" />
        <FormControlLabel value={false} control={<Radio />} label="On Start List" />
      </RadioGroup>
    </FormControl>
  );
}
