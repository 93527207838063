import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import DirectionsBikeOutlinedIcon from '@mui/icons-material/DirectionsBikeOutlined';
import axios from 'axios';
import UserControl from './UserControl';
import UserProfile from './UserProfile';
import { GoogleOAuthProvider, GoogleLogin, googleLogout   } from '@react-oauth/google';


const pages = ['Summary', 'Weekly Picks', 'Ranking', 'Rules'];
const settings = ['Profile', 'Switch Team', 'Logout'];

function ResponsiveAppBar( { setActiveComponent, userid, setUserid } ) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openUserDialog, setOpenUserDialog] = React.useState(false);
  const [openUserProfile, setOpenUserProfile] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState({name:'Guest', email:'', authenticated:false, defaultTeam: 0});
  const [teams, setTeams] = React.useState([]);

  React.useEffect(() => {
    fetch(`/api/user_players`)
      .then((res) => res.json())
      .then((data) => {
        setTeams(data);
      });
  },[openUserProfile,userInfo ]);  
  
  React.useEffect(() => {
    fetch(`/api/user_info`)
      .then((res) => res.json())
      .then((data) => {
        setUserInfo(data);
        setUserid(data.defaultTeam)
      });
  }, [setUserid]);  

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleItemClick = (event, page) => {
    setActiveComponent(page);
    setAnchorElNav(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = async (event, setting) => {
    if ( setting === 'Switch Team'){
        setOpenUserDialog(true);
    }
    else if ( setting === 'Profile'){
      setOpenUserProfile(true);
    }
    else if ( setting === 'Logout'){
      googleLogout()
      axios.post('/api/logout')
      .then((res) => {
        setUserInfo(res.data)
        setUserid(0)
      })

      console.log('Logout completed successfully');
    }
    setAnchorElUser(null);
  };

  return (
    <GoogleOAuthProvider clientId="607865565900-8ujpdui1jmclj1beinss2o0gb8f2kair.apps.googleusercontent.com">
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <PoolOutlinedIcon  sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <DirectionsBikeOutlinedIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
          <DirectionsRunOutlinedIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
            <Button
                key={page}
                onClick={(event) => handleItemClick(event, page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Typography variant="h6" sx={{ mr: 2,  letterSpacing: '.1rem'}}>
                {page}
                </Typography>
            </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleOpenNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page}  onClick={(event) => handleItemClick(event, page)} >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
      
          <Box sx={{ flexGrow: 0 }}>
          {userInfo.authenticated === false && 
            <GoogleLogin
                onSuccess={credentialResponse => {
                  axios.post('/api/google_callback', {token_response:credentialResponse})
                  .then((res) => {
                    setUserInfo(res.data)
                    setUserid(res.data.defaultTeam)
                  }).catch((error) => {
                    console.log("error logging in" + error)
                  })
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
            />};
          {userInfo.authenticated === true && 
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Typography variant="h6" sx={{ mr: 2,  color: 'white', letterSpacing: '.1rem'}}>
                {userInfo.name}
                </Typography>
                < Avatar />
              </IconButton>
            </Tooltip>
                        }

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={(event) => handleCloseUserMenu(event, setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <UserControl  userid = {userid} setUserid={setUserid} open={openUserDialog} setOpen={setOpenUserDialog} teams = {teams} />
        <UserProfile  userInfo = {userInfo} open={openUserProfile} setOpen={setOpenUserProfile} teams={teams} setTeams = {setTeams} />
      </Container>
    </AppBar>
    </GoogleOAuthProvider>
  );
}
export default ResponsiveAppBar;
