import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';

import Title from './Title';

function createData(time, amount) {
  return { time, amount: amount ?? null };
}

export default function Chart({ periods }) {
  const theme = useTheme();
  
  // Filter periods within the last two months
  const filteredPeriods = periods.filter(period => {
    const periodDate = new Date(period.start);
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 3);
    return periodDate >= twoMonthsAgo;
  });

  // Create data points
  const data = filteredPeriods.map(period => createData(period.start, period.score));

  // Calculate the maximum score
  const maxScore = filteredPeriods.length > 0 ? filteredPeriods.reduce((max, period) => 
    period.score > max ? period.score : max, 0) : 1; // Handle empty data case
  
  // Sort data by time
  data.sort((a, b) => new Date(a.time) - new Date(b.time)); // Use Date object for better comparison
  const slicedData = data.length > 1 ? data.slice(0, -1) : data; // Ensure data is sliced only if it exists

  // Conditionally render the chart
  if (slicedData.length === 0) {
    return <Title>No Data Available</Title>;
  }

  return (
    <React.Fragment>
      <Title>Scoring History</Title>
        <LineChart
          dataset={slicedData}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: 'point',
              dataKey: 'time',
              tickNumber: Math.min(5, slicedData.length), // Dynamic tick count based on data length
              tickLabelStyle: theme.typography.body2,
            },
          ]}
          yAxis={[
            {
              label: 'Weekly Score',
              labelStyle: {
                ...theme.typography.body1,
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2,
              max: maxScore * 1.1, // Add buffer to max score for better chart appearance
              tickNumber: 4, // You can adjust this dynamically as well
            },
          ]}
          series={[
            {
              dataKey: 'amount',
              showMark: false,
              color: theme.palette.primary.light,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
            [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-25px)',
            },
          }}
        />
    </React.Fragment>
  );
}
