import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid'
import PickSummary from './components/PickSummary';
import { useMediaQuery,useTheme } from '@mui/material';

export default function Ranking() {
  const [users, setUsers] = React.useState([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm')); // Adjust breakpoint as needed
  const scoreLabel =  isMobile ? "Score" : 'Last Score'
  React.useEffect(() => {
    fetch(`/api/players`)
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
      });
  },[]);  
  
  const summary_columns = [
    { field: 'id', headerName: 'ID'  },
    { field: 'name', headerName: 'Name', flex: 2, minWidth: 120 },
    { field: 'score', headerName: 'Total Score', flex: 0.5, minWidth: 100 },
    { field: 'weekly_score', headerName: scoreLabel, flex: 0.5, minWidth: 100 },
    { field: 'weekly_picks', headerName: '# of Picks', flex: 0.5, minWidth: 100 },
    { field: "period_id", headerName: '', flex: 0.5 , minWidth: 100,
      renderCell: (cellValues) => {
        return (PickSummary(cellValues.row.id, cellValues.row.period_id))
      }
    },    

  ]

  function PlayerGrid() {
    return <DataGrid
      disableColumnMenu
      autoHeight
      rows={users}
      columns={summary_columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        columns: {
          columnVisibilityModel: {
            id: false,
            score: !isMobile,
            weekly_picks: !isMobile,
          },
        },
      }}
      pageSizeOptions={[10, 15, 25, 50]}
      density={'compact'}
      disableRowSelectionOnClick
      disableColumnSelector
      ignoreDiacritics />;
  }

  return (
    <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                {PlayerGrid()}
              </Paper>
            </Grid>
          </Grid>

    </React.Fragment>
  );


}
