import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Rules = () => {
    return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <div >
                    <h2>Rules for Fantasy Triathlon</h2>
                    <p>Welcome to Fantasy Triathlon! Here are the rules:</p>
                    <ul>
                        <li>To play you would need to sign in with google, upon sign in a team will be created for you. From the settings menu you can modify the name of your team and create 4 more teams </li>
                        <li>Summary page will show your scoring history</li>
                        <li>Go to the Weekly Picks page to submit picks for the upcomming weekend</li>
                        <li>You can choose upto 10 athletes</li>
                        <li>Selections can be made between Midnight EST on Monday and Noon on Friday</li>
                        <li>Points are awarded on Monday following the competions</li>
                        <ul>
                            <li>10 points for 1st place </li>
                            <li>7 points for 2nd place </li>
                            <li>5 points for 3rd place </li>
                            <li>3 points for 4th and 5th place </li>
                            <li>1 point for 6th through 10th places </li>
                            <li>No points for lower placements </li>
                        </ul>
                        <li>Ranking page will let you see how your teams picks compare to others</li>
                    </ul>
                    <p>Have fun and good luck from Kupala Tri!</p>
                </div>

                <div>
                    <p><strong>PS:</strong> We will not share your name or email address with other participants of this forum or use it for any sort of marketing.</p>
                    <p><strong>PPS:</strong> For technical questions and suggestions, please reach out to kupala.tri@gmail.com.</p>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
    );
}

export default Rules;
