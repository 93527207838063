import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

export default function UserControl({userid, setUserid, open, setOpen, teams}) {

  const handleChange = (event) => {
    setUserid(Number(event.target.value) || '');
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') { 
      setOpen(false);
    }
  };

  return (
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Select Account</DialogTitle>
        <DialogContent>
              <Select
                native
                value={userid}
                onChange={handleChange}
                input={<OutlinedInput label="Player" />}
              >
          {teams.map(user => (
            <option key={user.name} value={user.id}>
              {user.name}
            </option>
          ))}
              </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
