import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Dashboard from './Dashboard';

const originalConsoleLog = console.log;

const logWithTimestamp = (message) => {
  const timestamp = new Date().toISOString();
  originalConsoleLog(`[${timestamp}] ${message}`);
};

console.log = logWithTimestamp;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Dashboard />
  </React.StrictMode>
);

// import reportWebVitals from './reportWebVitals';
// reportWebVitals(console.log);
